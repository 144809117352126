import styles from './Card.module.scss';
import cn from 'classnames';
import { Button } from '../Button';
import { Tag } from '../Tag';
import { Backdrop } from '../Backdrop';
import { BACKEND_URL, MOBILE_RESOLUTION } from '@/constants/constants';
import { useRouter } from 'next/router';
import { ItemData } from './types';
import { Sizes, Variants } from '@/types';
import { ArrowButton } from '../ArrowButton';
import { formatDate } from '@/utils/formatDate';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { parseText } from '@/utils/parseText';
import { useEffect, useRef, useState } from 'react';

export enum CardSize {
  XL = 'xl',
  LX = 'lx',
  L = 'l',
  M = 'm',
  SM = 'sm',
  S = 's',
  XS = 'xs',
}

export enum ButtonType {
  Arrow = 'arrow',
  Text = 'text',
}

export enum TagPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum CardType {
  Filled = 'filled',
  Panel = 'panel',
}

export function Card({
  name,
  info,
  tag,
  button,
  text,
  link,
  picture,
  className,
  closed,
  techClosed,
  pictureMob,
  size,
  dateFrom,
  dateTo,
  logo,
  cardType,
  typeName,
  buttonType = ButtonType.Text,
  tagPosition,
  fullWidth,
  halfWidth,
  noType,
  description,
  period,
  soon,
  mode,
  twoRowTitle,
  buttonLink,
  withButton,
  noInfo,
  buttonDisabled,
  pageMissing,
  onButtonClick,
  alert,
  withYear,
  contained,
  duplicateCardLink,
}: ItemData & {
  size?: CardSize;
  cardType: CardType;
  buttonType?: ButtonType;
  tagPosition?: TagPosition;
  fullWidth?: boolean;
  halfWidth?: boolean;
  noType?: boolean;
  description?: string;
  twoRowTitle?: boolean;
  withButton?: boolean;
  withYear?: boolean;
  noInfo?: boolean;
  buttonDisabled?: boolean;
  onButtonClick?: () => void;
  alert?: string;
  contained?: boolean;
  duplicateCardLink?: boolean;
}) {
  const cardClassName = cn(
    cardType === CardType.Filled ? styles.card : styles.cardPanel,
    {
      [styles.XL]: size === CardSize.XL,
      [styles.L]: size === CardSize.L,
      [styles.LX]: size === CardSize.LX,
      [styles.M]: size === CardSize.M,
      [styles.SM]: size === CardSize.SM,
      [styles.S]: size === CardSize.S,
      [styles.XS]: size === CardSize.XS,
      [styles.half]: size === CardSize.XS && halfWidth,
      [styles.fullWidth]: fullWidth,
      [styles.clickable]: link && !link.startsWith('#') && !pageMissing,
      [styles.techClosed]: techClosed,
      [styles.twoRowTitle]: twoRowTitle,
      [styles.contained]: contained,
    },
    className,
  );

  const router = useRouter();
  const width = useWindowWidth();
  const isMobile = width && width < MOBILE_RESOLUTION;
  const imageUrl =
    isMobile && pictureMob
      ? `${BACKEND_URL}${pictureMob}`
      : picture
      ? `${BACKEND_URL}${picture}`
      : '/png/empty.png';
  const TextFieldsRef = useRef<HTMLDivElement | null>(null);
  const ImageContainerRef = useRef<HTMLDivElement | null>(null);
  const [cardDate, setCardDate] = useState('');
  const [backdropHeight, setBackdropHeight] = useState('90%');

  useEffect(() => {
    if (TextFieldsRef.current && ImageContainerRef.current) {
      const textHeight = TextFieldsRef.current.clientHeight;
      const paddingBottom = Number(
        window
          .getComputedStyle(ImageContainerRef.current)
          .getPropertyValue('padding-bottom')
          .split('p')[0],
      );

      if (textHeight === 0) {
        setBackdropHeight('50px');
        return;
      }

      setBackdropHeight(textHeight + 20 + paddingBottom + 'px');
      return;
    }

    setBackdropHeight('90%');
    return;
  }, [info]);

  useEffect(() => {
    if (dateFrom) {
      setCardDate(formatDate(dateFrom, dateTo, withYear));
    }
  }, [dateFrom, dateTo]);

  const handleCardClick = () => {
    if (pageMissing) return;
    if (link && link.includes('http')) window.open(link, '_blank');
    if (link && !link.includes('http')) router.push(link);
    return;
  };

  const showInfoBlock = (dateFrom || dateTo || text || info) && !noInfo;

  const handleButtonClick = () => {
    onButtonClick && onButtonClick();

    if (buttonLink) {
      router.push(buttonLink);
      return;
    }

    if (link && link.startsWith('#')) {
      const element = document.getElementById(link);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      return;
    }

    if (!buttonLink && link && duplicateCardLink) {
      router.push(link);
      return;
    }
  };

  if (cardType === CardType.Panel)
    return (
      <div className={cardClassName} onClick={handleCardClick}>
        <div className={styles.top} ref={ImageContainerRef}>
          <img src={imageUrl} alt="object" className={styles.img} />
          {tag && tagPosition === TagPosition.Top ? (
            <Tag text={tag} className={styles.tag} />
          ) : (
            <div></div>
          )}
          {techClosed && (
            <p className={styles.closed}>
              {alert ? alert : 'Закрыто на техобслуживание'}
            </p>
          )}
          {logo && (
            <div className={styles.logoWrapper}>
              <img
                src={`${BACKEND_URL}${logo}`}
                alt="activity"
                className={styles.logo}
              />
            </div>
          )}
          {showInfoBlock && (
            <Backdrop className={styles.backdrop} height={backdropHeight} />
          )}
          {showInfoBlock && (
            <div className={styles.info} ref={TextFieldsRef}>
              {dateFrom && <p className={styles.date}>{cardDate}</p>}
              {text && <p className={styles.text}>{parseText(text)}</p>}
              {info && <p className={styles.date}>{parseText(info)}</p>}
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          {noType ? null : !typeName &&
            tag &&
            tagPosition === TagPosition.Bottom ? (
            <p className={styles.type}>{parseText(tag)}</p>
          ) : typeName ? (
            <p className={styles.type}>{parseText(typeName)}</p>
          ) : (
            <div className={styles.bottomEmpty} />
          )}
          <div className={styles.title}>{parseText(name)}</div>
          {description && (
            <div className={styles.description}>{parseText(description)}</div>
          )}
          {button && !soon && (
            <Button
              text={button}
              onClick={handleButtonClick}
              size={Sizes.Small}
              variant={Variants.Outline}
              className={styles.button}
              disabled={closed}
            />
          )}
          {!button && size === CardSize.SM && withButton && (
            <div className={styles.emptyButton} />
          )}
          {soon && !techClosed && (
            <Button
              text="Скоро"
              onClick={handleButtonClick}
              className={styles.buttonSoon}
              variant={Variants.Outline}
            />
          )}
        </div>
      </div>
    );

  if (cardType === CardType.Filled) {
    return (
      <div
        className={cardClassName}
        onClick={handleCardClick}
        ref={ImageContainerRef}
      >
        <img src={imageUrl} alt="object" className={styles.img} />
        {tag && tagPosition === TagPosition.Top ? (
          <Tag text={tag} className={styles.tag} />
        ) : (
          <div></div>
        )}
        <div className={styles.info} ref={TextFieldsRef}>
          <div>
            {tag && tagPosition === TagPosition.Bottom && (
              <Tag text={tag} className={styles.tag} />
            )}
            {period && <div className={styles.period}>{parseText(period)}</div>}
            <div className={styles.title}>{parseText(name)}</div>
            {text && <div className={styles.text}>{parseText(text)}</div>}
            {mode && <div className={styles.mode}>{parseText(mode)}</div>}
            {buttonType === ButtonType.Arrow &&
              !soon &&
              !techClosed &&
              !pageMissing &&
              link && (
                <ArrowButton
                  variant={Sizes.Small}
                  className={styles.arrowButton}
                />
              )}
            {buttonType === ButtonType.Text && button && !soon && (
              <Button
                text={closed ? 'Мест нет' : button}
                onClick={handleButtonClick}
                className={styles.button}
                disabled={closed || buttonDisabled}
              />
            )}
            {techClosed && (
              <p className={styles.closed}>
                {alert ? alert : 'Закрыто на техобслуживание'}
              </p>
            )}
            {soon && !techClosed && (
              <Button
                text="Скоро"
                onClick={handleButtonClick}
                className={styles.buttonSoon}
                variant={Variants.Outline}
              />
            )}
          </div>
          {info && <div className={styles.price}>{parseText(info)}</div>}
        </div>
        {(showInfoBlock || (name !== undefined && name !== '')) && (
          <Backdrop height={backdropHeight} />
        )}
      </div>
    );
  }

  return null;
}
